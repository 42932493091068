* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul,
li {
  list-style: none;
}

body {
  font-family: "Roboto";
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background {
  position: relative;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 240px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backgroundImg {
  position: absolute;
  z-index: -1;
  object-fit: none;
  object-position: 0 -240px;
}

@media screen and (max-width: 1279px) {

  .background {
    padding-bottom: 120px;
  }

  .backgroundImg {
    transform: scale(0.6);
    object-position: 100px -200px;
  }

}

@media screen and (max-width: 767px) {

  .backgroundImg {
    transform: scale(0.42);
    object-position: 0 0px;
  }

}

@media screen and (max-width: 519px) {

  .backgroundImg {
    transform: scale(0.40);
    object-position: 120px 10px;
  }

}


