@import "../../main.less";

.overlay {
  .modalOverlay();
}

.modal {
  .modalWindow();
  top: 5%;
  left: 15%;
  width: 70%;
  height: 70%;
}

.heading {
  font-weight: 500;
  font-size: 44px;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}

.text {
  max-width: 580px;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 40px;
}

.close {
  .modalClose();
  background-image: url("../../img/back-arrow.svg");
}

.backBtn {
  font-weight: 500;
  padding: 20px 32px;
  background: white;
  color: @blue2;
  font-family: Roboto ;
  border-radius: 500px;
  border: 2px solid @blue2;
  &:hover {
    cursor: pointer;
    background: @blue2;
    color: white;
  }
}

@media screen and (max-width: 1279px) {
  .modal {
    left: 10%;
    width: 80%;
    height: auto;
  }
  .backBtn{
    font-size: 12px;
  }
  .heading {
    font-size: 48px;
    margin-bottom: 24px;
  }

  .text {
    max-width: 450px;
    font-size: 16px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 767px) {
  .modal {
    left: 5%;
    width: 90%;
  }

  .heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 520px) {
  .modal {
    padding: 20px 30px;
    height: auto;
  }

  .heading {
    font-size: 40px;
  }

  .text {
    max-width: 327px;
  }
}
