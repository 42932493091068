@import "../../main.less";

.intro {
  width: 1200px;
}
.heading {
  font-size: 64px;
  font-weight: 500;
  max-width: 480px;
  margin-bottom: 24px;
  line-height: 64px;
}

.annotation {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 48px;
  max-width: 450px;
}

.applyBtn {
  .primaryBtn();
  font-weight: 500;
}

@media screen and (max-width: 1279px) {
  .intro {
    width: 688px;
  }

  .heading {
    font-size: 48px;
    max-width: 342px;
    margin-bottom: 16px;
    line-height: 48px;
  }
  .annotation {
    font-size: @textFontTablet;
    margin-bottom: 32px;
    max-width: 332px;
  }

  .applyBtn {
    padding: 17px 24px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .intro {
    width: 472px;
  }

  .heading {
    font-size: @headingFontTablet;
    width: 307px;
    line-height: 40px;
  }

  .annotation {
    width: 200px;
  }

  .applyBtn {
    padding: 17px 24px;
  }
}
@media screen and (max-width: 519px) {
  .intro {
    width: 327px;
  }

}
