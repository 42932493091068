@import "../../main.less";

.workingTerms {
  width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 120px 0;
}

.heading {
  .sectionHeadingDesktop();
  margin-bottom: 80px;
}

.strikethrough {
  position: absolute;
  margin-top: 10px;
  height: 1px;
  width: 91px;
  background-color: @gray2;
  transform: rotate(-5deg);
}

.card {
  max-width: 1200px;
  display: flex;
  column-gap: 104px;
  padding: 80px 64px;
  border-radius: 24px;
}

.trialCard {
  margin-bottom: 40px;
  background: linear-gradient(180deg, @gradient1 0%, @gradient2 100%);
}

.trialBtn {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 32px;
  margin-top: 48px;
  font-family: Roboto;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 500px;
  color: white;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: @blue3;
  }
  &:hover .arrow {
    fill: @blue3;
    stroke: @blue3;
  }
}

.arrow {
  margin-left: 10px;
  fill: white;
  stroke: white;
}

.cardHeading {
  .sectionSubheadingDesktop();
  max-width: 320px;

}
.freePeriod{
  padding-right: 120px;
}

.description {
  max-width: 452px;
  .textDesktop();
}

.oldPrice {
  font-size: 20px;
  font-weight: 300;
  color: @gray2;
  margin-top: 40px;
}

.newPrice {
  font-size: 56px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  color: @blue3;
}

.sale {
  display: flex;
  align-items: center;
}

.saleTag {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 18px;
  background: white;
  border-radius: 500px;
}

.saleText {
  font-size: 16px;
  font-weight: 300;
  max-width: 214px;
  margin-left: 16px;
  color: @gray2;
}

.subheading {
  font-size: 16px;
  font-weight: 300;
}

.transferCard {
  box-shadow: 0 8px 32px @blueShadow;
}

.price {
  font-size: 40px;
  font-weight: 500;
  margin-top: 16px;
  letter-spacing: -0.02em;
  color: @blue3;
}

.termsCard {
  background-color: @gray;
  margin-bottom: 40px;
}

.applyBtn {
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  color: @blue2;
  padding: 20px 32px;
  margin-top: 48px;
  border: 2px solid @blue2;
  border-radius: 500px;
  background-color: transparent;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background-color: @blue3;
    color: white;
  }
  &:hover .arrow2 {
    fill: white;
    stroke: white;
  }
}

.arrow2 {
  margin-left: 10px;
  fill: @blue3;
  stroke: @blue3;
}

.termItem {
  margin-bottom: 24px;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
}

.bulletIcon {
  min-width: 24px;
  max-height: 24px;
  margin-right: 12px;
  background-color: white;
  background-image: url("../../img/tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 4px 16px @blueShadow;
}

.secondary {
  color: white;
}

@media screen and (max-width: 1279px) {
  .workingTerms {
    width: 688px;
    padding: 80px 0;
  }
  .freePeriod{
    padding-right: 0;
  }

  .heading {
    font-size: @headingFontTablet;
  }

  .cardHeading {
    font-size: @subheadingFontTablet;
    max-width: 173px;
    padding-right: 40px;
  }

  .description {
    font-size: @textFontTablet;
  }

  .card {
    column-gap: 48px;
    padding: 40px;
  }

  .trialCard,
  .termsCard {
    margin-bottom: 24px;
  }

  .subheading {
    font-size: 12px;
  }

  .oldPrice {
    font-size: 16px;
    margin-top: 24px;
  }

  .strikethrough {
    width: 71px;
  }


  .newPrice {
    font-size: 40px;
  }

  .price {
    font-size: 28px;
    margin-top: 16px;
  }

  .saleTag {
    font-size: 16px;
    padding: 6px 14px;
  }
  .saleText {
    font-size: 12px;
    width: 112px;
  }

  .applyBtn,
  .trialBtn {
    margin-top: 30px;
    padding: 17px 24px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .workingTerms {
    width: 472px;
  }

  .card {
    flex-direction: column;
    padding: 40px;
  }

  .heading {
    font-size: @headingFontMobile;
    max-width: 472px;
    margin-bottom: 40px;
  }

  .cardHeading {
    margin-bottom: 16px;
    max-width: 100%;
    padding-right: 25px;
  }

  .sale,
  .price {
    margin-bottom: 32px;
  }

  .oldPrice {
    margin-top: 8px;
  }

  .strikethrough {
    width: 71px;
  }

  .description {
    font-size: @textFontTablet;
    max-width: 300px;
  }
}

@media screen and (max-width: 519px) {
  .workingTerms {
    width: 327px;
  }

  .heading {
    font-size: @headingFontMobile;
  }

  .cardHeading {
    max-width: 248px;
    padding-right: 5px;
  }
  .description {
    padding-right: 40px;
  }
}
