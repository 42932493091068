@import "../../main.less";

.clientSupport {
  width: 1200px;
  padding-bottom: 120px;
}

.heading {
  .sectionHeadingDesktop();
  margin-bottom: 80px;
}

.guaranteeCard {
  width: 788px;
  padding: 48px 64px;
  box-shadow: 0 8px 32px @blueShadow;
  border-radius: 24px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  height: 42px;
  width: 42px;
  background-repeat: no-repeat;
}

.icon1 {
  background-image: url("../../img/dialogue-ico.svg");
}

.icon2 {
  background-image: url("../../img/settings-ico.svg");
}

.icon3 {
  background-image: url("../../img/guarantee-ico.svg");
}

.textBlock {
  max-width: 660px;
}

.supportItemWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 0;
  }
}

.subheadingWrapper {
  display: flex;
  column-gap: 20px;
}

.subheading {
  .sectionSubheadingDesktop();
  width: 220px;
}

.cardHeading {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
}

.cardText {
  .textDesktop();
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.textSpace {
  margin-bottom: 22px;
}

.buttonsWrap {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
}

.whatsAppBtn {
  .secondaryBtn();
  padding: 20px 32px;
  color: @accent-green;
  font-size: 14px;
  margin-top: 32px;
  &:hover {
    color: white;
    background-color: @accent-green;
  }
  &:hover .whatsapp {
    fill: white;
  }
}

.whatsapp {
  margin-right: 8px;
  fill: @accent-green;
}

.contactBtn {
  .secondaryBtn();
  padding: 20px 32px;
  font-size: 14px;
  line-height: 16px;
  color: @blue2;
  margin-top: 32px;
  &:hover {
    color: white;
    background-color: @accent1;
  }
  &:hover .arrow2 {
    fill:white;
    stroke: white;
  }
}

.arrow2 {
  width: 19px;
  height: 14px;
  margin-left: 10px;
  fill: @blue3;
  stroke: @blue3;
}
.cardTextSupport{
  margin-bottom: 8px;
  margin-top: 16px;
}
.cardTextCorrector{
  margin-top: 16px;
}

@media screen and (max-width: 1279px) {
  .clientSupport {
    width: 688px;
    padding-bottom: 80px;
  }

  .heading {
    font-size: @headingFontTablet;
    margin-bottom: 40px;
  }

  .supportItemWrapper {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttonsWrap {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .whatsAppBtn{
    font-size: 12px;
    margin-top: 24px;
    padding: 16px 24px;
  }

  .whatsapp {
    width: 16px;
    height: 16px;
  }

  .contactBtn {
    font-size: 12px;
    margin-top: 0;
    padding: 16px 24px;
  }

  .subheading {
    font-size: @subheadingFontTablet;
    width: 158px;
  }

  .guaranteeCard {
    max-width: 452px;
    padding: 32px 24px;
  }

  .textBlock {
    max-width: 428px;
  }
  .cardHeading {
    font-size: 20px;
  }

  .cardText {
    font-size: @textFontTablet;
    margin-bottom:16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .cardTextSupport{
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .clientSupport,
  .textBlock {
    width: 472px;
  }

  .supportItemWrapper {
    flex-direction: column;
  }

  .heading {
    font-size: @headingFontMobile;
    width: 472px;
    margin-bottom: 50px;
  }

  .cardHeading {
    font-size: 20px;
  }

  .subheading {
    font-size: @subheadingFontTablet;
    width: 400px;
    margin-bottom: 40px;
  }

  .guaranteeCard {
    max-width: 472px;
    padding: 40px;
  }
}

@media screen and (max-width: 519px) {
  .clientSupport,
  .guaranteeCard,
  .textBlock,
  .heading {
    width: 328px;
  }

  .subheading {
    width: 250px;
  }
}
