@gray: #f2f5f5;
@gray2: #6d787a;
@blue: #007994;
@blue2: #008bad;
@blue3: #007994;
@lightBlue: #d0f3f8;
@blueShadow: rgba(0, 139, 173, 0.25);
@accent-green: #51b722;
@accent1: #ff786b;
@gradient1: #0082af;
@gradient2: #00c0d9;

@headingFontTablet: 40px;
@subheadingFontTablet: 28px;
@textFontTablet: 16px;

@headingFontMobile: 36px;

.primaryBtn {
  padding: 20px 32px;
  color: white;
  font-family: Roboto;
  background: @blue2;
  font-weight: 700;
  border-radius: 500px;
  box-shadow: 0 4px 16px @blueShadow;
  border: none;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: @accent1;
  }
}

.secondaryBtn {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 4px 16px @blueShadow;
  border: none;
  border-radius: 500px;
  background: white;
  &:hover {
    cursor: pointer;
  }
}

.sectionHeadingDesktop {
  font-weight: 500;
  font-size: 56px;
  letter-spacing: -0.02em;
}

.sectionSubheadingDesktop {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.textDesktop {
  font-size: 20px;
  font-weight: 300;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
}

.modalWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 50px;
  width: 70%;
  border-radius: 24px;
  z-index: 10;
  background-color: white;
}

.modalClose {
  border: 0;
  background: none;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
  &:hover stop {
    stopColor:  #FF786B;
  }
}
