@import "../../main.less";

.benefits {
  width: 1200px;
  padding: 120px 0;
}

.heading {
  .sectionHeadingDesktop();
  margin-bottom: 64px;
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  margin-bottom: 24px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 40px;
  box-shadow: 0 8px 32px @blueShadow;
  border-radius: 24px;
}

.cardText {
  max-width: 292px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
}

.icon1 {
  height: 66px;
  width: 82px;
  background-repeat: no-repeat;
  background-image: url("../../img/automatization-ico.svg");
}

.icon2 {
  height: 66px;
  width: 52px;
  background-repeat: no-repeat;
  background-image: url("../../img/medrecords-ico.svg");
}

.icon3 {
  height: 66px;
  width: 64px;
  background-repeat: no-repeat;
  background-image: url("../../img/accounting-ico.svg");
}

.list {
  max-height: 294px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}

.listText {
  .textDesktop();
  width: 408px;
}

.listIcon {
  height: 48px;
  width: 48px;
  margin-right: 24px;
  background-repeat: no-repeat;
}

.icon4 {
  background-image: url("../../img/file-ico.svg");
}

.icon5 {
  background-image: url("../../img/money-ico.svg");
}

.icon6 {
  background-image: url("../../img/savings-ico.svg");
}

.icon7 {
  background-image: url("../../img/shield-ico.svg");
}

.icon8 {
  background-image: url("../../img/start-ico.svg");
}

.icon9 {
  background-image: url("../../img/safe-ico.svg");
}

.lineBreak {
  display: none;
}

@media screen and (max-width: 1279px) {
  .cardsWrapper {
    margin-bottom: 0;
  }

  .benefits {
    width: 688px;
    padding: 80px 0;
  }

  .heading {
    font-size: @headingFontTablet;
    margin-bottom: 48px;
  }
  .card {
    padding: 32px 24px;
  }
  .cardText {
    max-width: 164px;
    font-size: @textFontTablet;
  }
  .listText {
    max-width: 236px;
    font-size: @textFontTablet;
  }
}

@media screen and (max-width: 767px) {
  .benefits {
    width: 472px;
  }

  .heading {
    font-size: @headingFontMobile;
    max-width: 432px;
  }

  .card {
    margin-bottom: 24px;
    flex-grow: 1;
  }

  .card:last-child {
    flex-direction: row;
    align-items: center;
    .cardText {
      max-width: 180px;
      margin-left: 24px;
      margin-top: 0;
    }
  }

  .list {
    max-height: 498px;
  }

  .listItem {
    height: 96px;
    margin-top: 30px;
    align-items: start;
  }

  .listText {
    max-width: 136px;
  }

  .lineBreak {
    display: block;
  }
}

@media screen and (max-width: 519px) {
  .benefits {
    width: 327px;
  }

  .cardsWrapper {
    flex-direction: column;
  }

  .card:last-child {
    flex-direction: column;
    align-items: flex-start;
    .cardText {
      max-width: 280px;
      margin-left: 0;
      margin-top: 24px;
    }
  }

  .cardText {
    max-width: 280px;
  }

  .list {
    max-height: 567px;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .listItem {
    height: auto;
    margin-top: 40px;
    &:nth-child(1) {
      order: 1
    }
    &:nth-child(2) {
      order: 3
    }
    &:nth-child(3) {
      order: 5
    }
    &:nth-child(4) {
      order: 2
    }
    &:nth-child(5) {
      order: 4
    }
    &:nth-child(6) {
      order: 6
    }
  }

  .listText {
    max-width: 216px;
  }
}
