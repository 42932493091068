@import "../../main.less";

.overlay {
  .modalOverlay();
  overflow: auto;
}

.modal {
  .modalWindow();
  top: 5%;
  left: 15%;
  height: auto;
}

.modalFlexContainer {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.errorText {
  font-weight: 300;
  font-size: 16px;
  margin-top: 48px;
  color: red;
}

.heading {
  font-weight: 500;
  font-size: 44px;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}

.text {
  max-width: 580px;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 50px;
}

.close {
  .modalClose();
}

.sendBtn {
  .primaryBtn();
  font-weight: 500;
  padding: 20px 32px;
  margin-top: 48px;
}

.label {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.input {
  width: 516px;
  border: none;
  padding: 24px 32px;
  box-shadow: 0 4px 16px @blueShadow;
  border-radius: 16px;
  cursor: pointer;
}

.inputError {
  outline: 1px solid red;
  box-shadow: 0 0 5px red;
}

.errorMessage {
  margin-top: 10px;
  font-size: 15px;
  color: red;
}

@media screen and (max-width: 1279px) {
  .modal {
    left: 10%;
    width: 80%;
    height: auto;
  }

  .input {
    width: 90%;
    padding: 18px 24px;
  }

  .heading {
    font-size: 48px;
    margin-bottom: 24px;
  }

  .text {
    max-width: 450px;
    font-size: 16px;
    margin-bottom: 48px;
  }

  .label {
    font-size: 12px;
  }
  .sendBtn{
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .modal {
    left: 5%;
    width: 90%;
  }

  .input {
    padding: 18px 24px;
  }

  .heading {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 520px) {
  .modal {
    padding: 20px 30px;
    height: auto;
  }

  .heading {
    font-size: 36px;
  }

  .text {
    max-width: 327px;
  }
}
