@import "../../main.less";

.headerWrapper {
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.container {
  display: flex;
  width: 1200px;
  height: 74px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.logo {
  height: 24px;
  min-width: 167px;
  background-image: url("../../img/ntdent-logo.svg");
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
}

.menu {
  display: flex;
}

.link {
  margin-right: 32px;
  padding: 13px 0;
  a {
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    color: black;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.contactBtn {
  .secondaryBtn();
  font-size: 12px;
  margin-left: 16px;
  padding: 12px 24px;
  color: @accent-green;
  &:hover {
    color: white;
    background-color: @accent-green;
  }
  &:hover .whatsapp {
    fill: white;
  }
}

.whatsapp {
  margin-right: 8px;
  fill: @accent-green;
}

.trialBtn {
  .secondaryBtn();
  font-size: 12px;
  color: @blue;
  padding: 13px 24px;
  margin-left: 8px;
  &:hover {
    color: white;
    background-color: @accent1;
  }
}

@media screen and (max-width: 1279px) {
  .container {
    justify-content: space-between;
    width: 688px;
    margin-bottom: 37px;
  }

  .logo {
    height: 18px;
    min-width: 125px;
    background-size: contain;
  }

  .link {
    display: none;
  }

  .trialBtn {
    font-size: 10px;
    line-height: 12px;
    padding: 10px 16px;
  }

  .contactBtn {
    font-size: 10px;
    padding: 10px 16px;
    span {
      padding-top: 1px;
    }
  }

  .whatsapp {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 472px;
    margin-bottom: 11px;
  }

  .contactBtn {
    display: none;
  }
}

@media screen and (max-width: 519px) {
  .container {
    width: 327px;
    margin-bottom: 11px;
  }

  .trialBtn {
    padding: 10px 16px;
    font-size: 10px;
  }
}
