@import "../../main.less";

.footer {
  width: 100%;
  background-color: @gray;
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

.contentWrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.navWrapper {
  display: flex;
  column-gap: 142px;
}

.logo {
  height: 24px;
  min-width: 167px;
  margin-bottom: 32px;
  background-image: url("../../img/ntdent-logo.svg");
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
}

.contacts > li, a {
  color: unset;
  font-size: 13px;
  font-weight: 500;
}

.phone {
  margin-bottom: 20px;
  &:hover {
    color: @blue;
    cursor: pointer;
  }
}

.email {
  font-weight: 400;
  margin-bottom: 11px;
  text-decoration: underline;
  &:hover {
    color: @blue;
    cursor: pointer;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  li {
    margin-bottom: 24px;
  }
}

.link {
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  &:hover {
    cursor: pointer;
    color: @blue;
  }
}

.btnWrapper {
  display: flex;
  margin-bottom: 30px;
}

.contactBtn {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto;
  white-space: nowrap;
  color: @accent-green;
  padding: 12px 24px;
  border: 1px solid @accent-green;
  border-radius: 500px;
  background-color: transparent;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: @accent-green;
  }
  &:hover .whatsapp {
    fill: white;
  }
}

.whatsapp {
  margin-right: 8px;
  fill: @accent-green;
}

.trialBtn {
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto;
  color: @blue;
  white-space: nowrap;
  padding: 12px 24px;
  border: 1px solid @blue;
  border-radius: 500px;
  background-color: transparent;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: @blue2;
  }
}

.copyright {
  font-size: 13px;
  font-weight: 400;
  color: @gray2;
  cursor: pointer;
}

@media screen and (max-width: 1279px) {
  .footer {
    padding: 50px 40px;
  }

  .contentWrapper {
    width: 768px;
  }

  .navWrapper {
    column-gap: 60px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 20px;
    }
  }

  .link,
  .contacts > li,
  .copyright {
    font-size: 11px;
  }

  .contactBtn {
    margin-left: 0;
  }
  .logo{
    margin-bottom: 30px;
  }
  .btnWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 36px;
  }

  .contactBtn{
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
    color: @accent-green;
    padding: 10px 16px;
    border: 1px solid @accent-green;
    border-radius: 500px;
    background-color: transparent;
    span {
      padding-top: 1px;
    }

  }
  .trialBtn {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: @blue;
    white-space: nowrap;
    padding: 10px 16px;
    border: 1px solid @blue;
    border-radius: 500px;
    background-color: transparent;

  }
  .whatsapp {
    margin-right: 8px;
    fill: @accent-green;
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  .navWrapper {
    column-gap: 122px;
  }

  .contentWrapper {
    width: 472px;
    flex-wrap: wrap;
  }

  .contactBtn {
    margin-left: 12px;
    margin-top: 0;
  }

  .link {
    margin-bottom: 20px;
  }

  .btnWrapper {
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .footerWrapper {
    padding: 50px 16px;
  }
}

@media screen and (max-width: 519px) {
  .contentWrapper {
    width: 327px;
  }
  .navWrapper {
    column-gap: 60px;
  }
}
