@import "../../main.less";

.card {
  position: fixed;
  background: white;
  left: 20px;
  bottom: 14px;
  width: 524px;
  display: flex;
  align-items: center;
  column-gap: 18px;
  padding: 40px 48px;
  box-shadow: 0 8px 32px @blueShadow;
  border-radius: 24px;
}

.warningText {
  width: 288px;
  font-weight: 300;
  font-size: 16px;
}

.acceptBtn {
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  color: @blue2;
  padding: 20px 32px;
  border: 2px solid @blue2;
  border-radius: 500px;
  background-color: transparent;
  &:hover {
    cursor: pointer;
    background-color: @blue2;
    color: white;
  }
}

@media screen and (max-width: 1279px) {
  .card {
    width: 388px;
    padding: 24px;
  }
  .warningText {
    font-size: 12px;
    width: 224px;
  }

  .acceptBtn {
    font-size: 12px;
    padding: 17px 24px;
  }
}

@media screen and (max-width: 767px) {
  .card {
    justify-content: space-between;
    width: 94%;
    left: 3%;
    padding: 24px 34px;
  }

  .warningText {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {
  .warningText {
    width: 58%;
  }
}
