@import "../../main.less";

.systemFeatures {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: @gray;
  padding: 120px 0;
}

.contentWrapper {
  width: 1200px;
}

.textBlock {
  max-width: 374px;
}

.heading {
  .sectionHeadingDesktop();
  margin-bottom: 64px;
}

.subheading {
  .sectionSubheadingDesktop();
  margin-bottom: 40px;
}

.highlighted {
  font-weight: 500;
}

.role {
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  padding: 8px 16px;
  background: @lightBlue;
  color: @blue;
  border-radius: 500px;
  margin-bottom: 16px;
  &::before {
    margin-right: 10px;
    content: url("../../img/person-ico.svg");
  }
}

.imgContainer {
  width: 747px;
}

.preview {
  width: 100%;
  box-shadow: 0 8px 32px @blueShadow;
  border-radius: 24px;
}

.featureBlock {
  display: flex;
  column-gap: 80px;
  align-items: center;
  margin-bottom: 80px;
  p {
    .textDesktop();
    margin-bottom: 20px;
  }
  &:nth-child(odd) > .textBlock {
    order: 1;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1279px) {
  .systemFeatures {
    padding: 80px 0;
  }
  .role {
    font-size: 12px;
  }
  .textBlock {
    p {
      font-size: @textFontTablet;
    }
  }

  .contentWrapper {
    width: 688px;
  }
  .textBlock {
    max-width: 688px;
  }

  .featureBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    &:nth-child(odd) > .textBlock {
      order: 0;
    }
  }

  .heading {
    font-size: @headingFontTablet;
    margin-bottom: 48px;
  }

  .subheading {
    font-size: @subheadingFontTablet;
    margin-bottom: 24px;
  }

  .imgContainer {
    width: 688px;
  }
}

@media screen and (max-width: 767px) {

  .contentWrapper,
  .textBlock {
    width: 472px;
    p {
      font-size: @textFontTablet;
    }
  }

  .heading {
    font-size: @headingFontMobile;
  }


  .imgContainer {
    width: 472px;
  }


}

@media screen and (max-width: 519px) {
  .contentWrapper,
  .textBlock {
    width: 327px;
  }

  .heading {
    font-size: @headingFontMobile;
  }

  .imgContainer {
    width: 327px;
  }
}
