@import "../../main.less";

.overlay {
  .modalOverlay();
  overflow: auto;
}

.modal {
  .modalWindow();
  top: 5%;
  left: 15%;
  max-height: 90vh;
  overflow: scroll;
  h4 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}


